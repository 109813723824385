import { StoryblokComponent, storyblokEditable } from "gatsby-source-storyblok"
import React, { useMemo } from "react"

import { useIsBreakpoint } from "../../../../utils/V2/screen"
import GridSection from "../../../V2/Layouts/GridSection"

import { getUrlFromStoryblokLink, linkIsNotNull } from "@utils/storyblok"

const StoryblokGridSection = ({
  blok,
}: Storyblok.BlokProps<Storyblok.GridSection>) => {
  const isMobile = useIsBreakpoint("mobile")

  const gridContent = useMemo(() => {
    return isMobile && blok.isMobileGridReversed
      ? blok.content.slice(0).reverse()
      : blok.content
  }, [isMobile, blok.isMobileGridReversed, blok.content])

  return (
    <GridSection
      id={blok.id}
      headingSize={blok.headingSize}
      alignment={blok.alignment}
      superscriptText={blok.superscriptText}
      overrideSuperscriptColor={blok.overrideSuperscriptColor}
      headingText={blok.headingText}
      overrideHeadingTextColor={blok.overrideHeadingTextColor}
      headingBodyText={blok.headingBodyText}
      overrideHeadingBodyColor={blok.overrideHeadingBodyColor}
      primaryBackgroundColor={blok.primaryBackgroundColor}
      secondaryBackgroundColor={blok.secondaryBackgroundColor}
      ctaType={blok.ctaType}
      ctaText={blok.ctaText}
      ctaColor={blok.ctaColor}
      trackingEvent={blok.trackingEvent}
      trackingEventKey={blok.trackingEventKey}
      trackingEventValue={blok.trackingEventValue}
      ctaLink={
        blok.ctaLink && linkIsNotNull(blok.ctaLink)
          ? getUrlFromStoryblokLink(blok.ctaLink)
          : undefined
      }
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    >
      {gridContent.map((content) => (
        <StoryblokComponent key={content._uid} blok={content} />
      ))}
    </GridSection>
  )
}

export default StoryblokGridSection
